.page-loader {
    &.dimmer {
        background-color: rgba(255, 255, 255, .6);
        position: fixed;
        z-index: 999999;
    }

    p {
        font-family: "Titillium web", Verdana, Arial, monospace;
    }
}
.loader-mini {
    .spinner-border {
        width: 1.5rem;
        height: 1.5rem;
    }
}