
//MODAL CUSTOM
.modal.modal-custom .modal-dialog {

     .modal-content {

        .modal-body  {
            padding: 10px; 
        }
    }

}