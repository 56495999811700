@import '../../../style/variable.scss';

.it-footer {
	
	.it-footer-main {

		padding: 0rem;
		background-color: $bg-color-footer-main;
		min-height: 10rem;
		// max-height: 35rem;
		color: #ffffff;
		font-size: 1.4rem;

		a {
			color: #ffffff;
		}
		a:hover {
			opacity: 80%;
		}

		.iniziativa {
			border-left: 1px solid white;
			font-style: italic;
			font-size: 16px;
			padding-left: 30px;
			height: 60px;
		}
	
		.img-fesr {
			max-width: 70%;
			margin-left: 0px;
		}
	

		.footer-block {
		

			.footer-title {
				padding-bottom: 2.0rem;
				font-size: 23px;
				border-bottom: 1px solid;
				//margin-bottom: 10px;
			}

			.item-section {
				padding: 1.6rem;
				a {
					font-weight: 700;
				}

				span{
					font-weight: bold;
					font-size: 18px;
				}
	
				p {
					font-size: 15px;
				}
			}

		}

	}

	.it-footer-bottom {
		background-color: $bg-color-footer-bottom;
	}


}
