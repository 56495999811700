.pagination{
    .page-item{
        .page-link {
            &[aria-current=false] {
                border: none;
                color: #fff;
                background-color: #06c;
                pointer-events: all;
            }
            &[aria-current=true] {
                border: 1px solid #06c;
                color: #06c;
                pointer-events: none;
            } 
        } 
    }
}