@import './variable.scss';

.au-callout{
    padding: 2.5rem 2.222rem;
    border: 2px solid #5b6f82;
    position: relative;
    margin: 1.75rem 0 1rem;
    border-radius: 8px;


    &.au-callout-info {
        border-color: $color-principale;
        .callout-title {
            color: $color-principale;
        }
    }

    &.au-callout-warning {
        border-color: $color-warning;
        .callout-title {
            color: $color-warning;
        }
    }


    &:not(.callout-highlight):not(.callout-more) .callout-title {
        position: absolute;
        font-size: 1rem;
        padding: 0 1.389rem;
        background-color: $bg-color-box;
        top: -0.78em;
    }


    .callout-title {
        font-weight: 700;
        text-transform: uppercase;
        font-size: 1rem;
        margin-right: 2.222rem;
        letter-spacing: .06rem;
        color: #5b6f82;
        display: flex;
        align-items: center;
        
        .icon {
            margin-right: 5px;
        }
    }
}

