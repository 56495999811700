@import 'variable.scss';

.it-header-wrapper {
    
    position: relative;
    z-index: 5;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;

    .it-header-center-wrapper {

        padding-top: 0;
        background-color: $bg-barra-header-center;
    
        .it-header-center-content-wrapper .it-brand-wrapper a .icon {
            height: unset;
            width: 72px;
        }
    
    } 
    
    .it-header-slim-wrapper {
        background-color: $bg-barra-header-slim;
    
    
        .it-header-slim-wrapper-content {
    
            .navbar-brand{
                font-size: 18px;
            }
            
            a .icon-user {
                background-color: white;
                border-radius: 14px;
                padding: 6px;
                width: 30px;
                height: 30px;
            }
    
            .it-header-slim-right-zone {
                background-color: $bg-barra-header-center;
                font-weight: 600;
            }
    
            a.dropdown-toggle[aria-expanded=true] {
                .icon-user {
                    -webkit-transform: none;
                    transform: none;
                }
            }
        }
    
        .menu-info{
            font-size: 16px;
            padding: 0px 24px;
            p{
                font-weight: 700;
                font-size: 14px;
                margin-top: 10px;
            }
        }
        
    } 

}



