
.table {
    thead  {
        th {
            vertical-align: middle;
            font-size: 20px;
            line-height: 1em;
            height: 50px;
            border-bottom: 2px solid #909192;
            padding: 5px;
        }
        .table-order {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;

            .icon-direction{
                display: flex;
                flex-direction: column;
                margin-left: .5rem;
            }
        }
        .th-header-col-order {
            display: flex;
            align-items: center;
            cursor: pointer;
            justify-content: space-between;

            span:first-child {
                width: 90%;
            }
            .icon-direction {
                width: 15px;
                display: flex;
                margin-left: 5px;
                
                &::after {
                    position: relative;
                    top: -2px;
                    width: 15px;
                    height: 15px;
                }
            }
            &:not(.attivo_DESC, .attivo_ASC) .icon-direction::after {
                content: url('./../assets/icons/arrow-up-down.svg')
            }
            &.attivo_ASC .icon-direction::after {
                content: url('./../assets/icons/arrow-up.svg')
            }
            &.attivo_DESC .icon-direction::after {
                content: url('./../assets/icons/arrow-down.svg')
            }
        }
    }

    tbody {
        td {
            vertical-align: top;
            font-size: 16px;
            padding: 5px;
            font-weight: 500;
        }
        td:is(:last-child) {
            vertical-align: middle;
        }
        .link-list-wrapper .link-list {
            box-shadow: $box-shadow;
            border-radius: 5px;
    
            a.list-item {
                font-weight: 600;
                color: $color-principale;
            }
        }

        .dropdown {
            .nav-link {
                //padding-right: 0;
               // padding-left: 0;
            }
            .dropdown-menu {
                padding: 0;
                margin-top: 5px;
            }
        }
    }
}