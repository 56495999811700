@import "../../../style/variable.scss";

.sidebar-menu {
    background-color: $bg-sider-menu;
    border-right: 1px solid $border-color-sider-menu;
    min-height: 70rem;
    width: $width-sider-menu;
}

.sidebar-wrapper {

    padding:0;

    &.it-line-right-side {
        border-right: 0;
    }

    .sidebar-linklist-wrapper {

        .badge-primary {
            background-color: $color-red;
        }
        .link-list-wrapper {

            cursor: pointer;
            span { 
                color: $color-testo-sider-menu;  
                font-weight: 600; 
                text-decoration: none;
                margin-right: 12px;
                font-size: 16px;
            }

            ul {
                li {
                    a {
                        white-space: initial;
                        padding: 18px 10px 18px 15px;
                        display: flex;
                        align-items: center;
                    }
                    .right-icon .icon.right {
                        right: 6px;
                        top: 5px;
                    }
                }
            }
            .link-list {

                .header  {
                    font-size: 18px;
                    padding: 30px 24px;
                    margin: 0;
                }
                >li {
                    text-transform: uppercase;
                    .icon{
                        &[aria-expanded=true]  {
                            -webkit-transform: scaleY(-1);
                            transform: scaleY(-1);
                        }
                    }
                    .active.list-item {
                        background-color: $color-principale;
                        width: 230px;
                        height: 56px;
                        border-radius: 0 30px 30px 0;
                        text-decoration: none;

                        span {
                            color: $color-testo-active-sider-menu;
                        }
                    }
                }
            }

            .link-sublist {
                padding-left: 0;

                >li {
                    .list-item {
                        padding-left: 25px;
                        width: 230px;
                        height: 56px;
                    }
                    .active.list-item {
                        background-color: $color-principale;
                        width: 230px;
                        height: 56px;
                        border-radius: 0 30px 30px 0;
                        text-decoration: none;

                        span {
                            color: $color-testo-active-sider-menu;
                        }
                    }
                }
            }
        }
    }
} 