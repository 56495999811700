@import './variable.scss';

.steppers {

    .steppers-header li {
        text-transform: unset;
        font-weight: 600;
        background-color: white;
        margin: 2px 0;
        height: 80px;

        &.active {
            background-color: $color-principale;
            color: white;
            border-bottom: 3px solid black;
            display: flex;
            align-items: center;
            padding-left: 1rem;
        }
        &.deactive {
            cursor: not-allowed;
        }
        &.confermato {
            color: #06c;
            //border-left: 2px solid $color-green;
        }
        .icon.steppers-success {
            fill: $color-green;
        }
    }

    .steppers-content {
        height: fit-content !important;
        border: none !important;
        background: none !important;
    }

}
