@import '../../../style/variable.scss';

.no-form-group {
  margin-bottom: 0 !important;
  max-width: 100%;
}

.checkbox-scelta {
  input {
    height: 1rem;
  }
}

.elenco-bandi {
    .finished {
        border-left: 10px solid #cc334d !important
    }
}

.elenco-bandi-eq {
    .candidature-inviate {
        border-left: 10px solid #14961f !important;
    }
}

.visualizza-bando{

    .dati-istanza {

        .candidatura-curriculum label {
            position: initial;
            white-space: normal!important;
            line-height: normal;
        }
    
        .condanne-penali label,
        .periodo-prova label {
            top: 1rem;
            left: 0.5rem;
        }
    
        .form-group label.active {
            transform: translateY(0)
        }
    
        .form-check [type=checkbox]:focus+label, .form-check [type=radio]:focus+label, .toggles label input[type=checkbox]:focus+.lever {
            border-color: transparent!important;
            -webkit-box-shadow: unset !important;
            box-shadow: unset !important;
            outline: 0!important;
        }
    }
    
}

.upload-gestione-messive {

    .error-upload-massivo{
        border-left: 10px solid #cc334d !important;
      }

}

/* .elenco-candidature-modello-istanza {
  div[data-component="DocumentView"] {
    div {
      display: none;
    }
  }
} */

.elenco-candidature {
    .nessuna-preferenza {
        .form-check [type=checkbox]:focus+label, .form-check [type=radio]:focus+label, .toggles label input[type=checkbox]:focus+.lever {
            border-color: transparent!important;
            -webkit-box-shadow: unset !important;
            box-shadow: unset !important;
            outline: 0!important;
        }
        .form-group {
            margin-bottom: 0;
        }
        .form-group label.active {
            transform: translateY(0)
        }
        label {
            margin-bottom: 0!important;
            margin-top: 0.5rem!important;
        }
        
        .btn-detail {
            cursor: pointer;
            padding: 5px 10px;
            font-size: 15px;
        }
    
        .table-container {
            max-height: 400px;
            overflow-y: auto;
        }
        
        .table th,
        .table td {
            min-width: 150px;
        }
    }
}

.error-message-date{
    color: var(--red) !important;
}