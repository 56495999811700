.cicle-status-active {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: green;
}

.cicle-status-deactive {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: rgb(177, 0, 0);
}

small.text-description {
    color: #5a768a!important;
}