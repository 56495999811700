@import './variable.scss';


.card-custom {
    margin: 10px;

    .item-card {
        &::after {
            display: none;
        }
    }

    .card-notifica {
        border:1px solid $border-color;
        border-radius: 5px;
        box-shadow: $box-shadow;
        margin: 10px;

        .card-header{
            padding: 15px;

            .icon-menu {
                display: flex;
                justify-content: center;
                align-items: center;
                
                .item-icon-menu {
                    cursor: pointer;

                    .busta {
                        color: $color-principale;
                        font-size: 24px;
                        line-height: 0;
                        padding: 5px;
                    }
                }
            }
            .etichetta {
                top: 20px;
                right: 30px;
            }
        }
        .stato-notifica {
            padding: 5px 15px;
            background-color: $color-principale;
            color: #fff;
            text-align: center;
            background-color: $color-principale;
        }

        .card-body {
            padding: 15px;

            .card-title{
                color: $color-principale;
            }

            .card-text{
                font-weight: 500;
            }
            .card-feedback {
                border-top: 1px solid $border-color;
                border-bottom: 1px solid $border-color;
                min-height: 30px;
                padding: 10px;
                font-style: italic;
                font-size: 15px;
                font-weight: 600;
                margin-bottom: 15px;

                div:nth-of-type(1) {
                    border-right: 1px solid $border-color;
                }
            }

            .card-footer {
                font-style: italic;
                font-size: 15px;
                font-weight: 600;
                padding: 0;
                width: 100%;
                border: none;

                .item-allegato{
                    width: 50%;
                    overflow-x: hidden;
                    text-wrap: nowrap;
                    cursor: pointer;

                    .icon {
                        height: 22px;
                        width: 24px;
                    }
                    
                    span {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .card-candidatura {
        margin: 10px;

        .titolo-dipendente {
            font-weight: 600;
            color: #767676;
        }

        .nome-dipendente {
            font-weight: 600;
            color: $color-principale;
        }

        .titolo-struttura {
            font-weight: 600;
            color: #767676;
            font-size: 16px;
        }

        .nome-struttura {
            font-weight: 600;
            color: $color-principale;
            font-size: 0.8rem;
        }

        .conteso,
        .modello-istanza {
            .titolo {
                font-weight: 600;
                color: #767676;
                font-size: 16px;
                font-style: italic;
                line-height: 16px;
            }
            .valore {
                margin-top: 5px;
                font-weight: 600;
                color: green;
                font-style: italic;
            }
        }
        .info-scelta {
            font-weight: 600;
            font-style: italic;
            font-size: 16px;
            line-height: 1rem;
            float: left;
        }
    }
}

.card.border-bottom-card::before {
    display: none;
}

