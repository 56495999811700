
.modifica-proposta-incarico {

    .au-autocomplete {
        display: flex;
        position: relative;
        & > div {
            flex: 1
        }
        button {
            margin-top: calc(1.5rem + 7px);
            height: 50%;
        }
    }

    .candidato-incarico button {
        margin-top: 2.58rem
    }
}