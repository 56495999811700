
//variabili generiche
$color-red: #ff0000;
$color-green: #008758;
$color-blue: #0000ff;
$color-yellow: #facf20;
$color-gray: #d6d6d6;
$color-white: #ffffff;
$color-orange: #ffaf2f;
$color-info: #1a5bff;
$color-disabled: #6a6a6a;
$box-shadow: 0px 5px 9px rgba(25, 22, 22, 0.43);

//COLORI APP
$color-principale: #0066cc;
$color-secondario: #004c99;
$body-text-color: #212529;
$color-warning: #a66300;

$border-color: #d9dadb;
$border-color-focus: #228ffb5a;

$bg-campo-disabilitato: #d9dadb;
$bg-check-disabilitato: #949494;

//HEADER
$bg-barra-header-slim: $color-secondario;
$bg-barra-header-center: $color-principale;

//SIDE MENU
$width-sider-menu: 240px;
$bg-sider-menu: #f3f3f3;
$color-testo-sider-menu: $color-principale;
$color-testo-active-sider-menu: white;
$border-color-sider-menu: $border-color;

//PAGE
$bg-color-box: rgb(247 247 247);

//FOOTER
$bg-color-footer-main: $color-secondario;
$bg-color-footer-bottom: #003366;

