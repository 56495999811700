@import './../../style/variable.scss';

.nuova-convenzione,
.modifica-convenzione,
.dettaglio-convenzione {

    .titolo-sezione {
        border-left: 2px solid $color-principale;
        border-bottom: 2px solid $color-principale;
        font-weight: 600;
        margin-left: 10px;
        font-size: 1.2rem;
        padding-left: 10px;
    }
}