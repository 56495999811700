@import '../../style/variable.scss';

.organigramma-tree {
    font-size: 14px;
    background-color: #f2f7fc;

    .react-transform-wrapper {
        width: 100%;
        height: 460px;
        cursor: grab;
        padding-top: 20px;
        :active {
            cursor: grabbing
        }
    }
    #active {
        &::before{
            content: "";
            position: absolute;
            opacity: 0.6;
            background-color: red;
            border-radius: 50%;
            width: 20px;
            height: 20px;
            left: -10px;
            top: -8px
        }
        position: relative;
        padding: 10px;
        border-radius: 8px;
        display: inline-block;
        background-color: #06c;
        color: white;
        margin-bottom: 3px;
        border-width: 1px;
        &:not(.active) {
            cursor: pointer;
        }
        cursor: default
    }
    .so {
        padding: 10px;
        border-radius: 8px;
        display: inline-block;
        border: 1px solid rgb(0, 0, 0);
        font-weight: bold;
        margin: 3px;
        &.so-dipartimento {
            background-color: rgba(0, 102, 204, 0.6);
            color: white;
            min-height: 100px;
            min-width: 250px;
        }
        &.so-sezione {
            background-color: rgba(217, 218, 218, 1);
            color: $color-secondario;
            min-height: 100px;
            min-width: 250px;
        }
        &.so-servizio {
            background-color: rgba(0, 102, 204, 0.1);
            color: $color-secondario;
            min-height: 100px;
            min-width: 250px;
        }
        &:hover {
            padding: 10px;
            border-radius: 8px;
            display: inline-block;
            background-color: #06c;
            color: white;
            margin-bottom: 3px;
            border-width: 1;
            &:not(.active) {
                cursor: pointer;
            }
        }
    }

    .controls {
        cursor: pointer;
        font-weight: bold;
        border: 1px solid lightgray;
        padding: 0px 10px;
        border-radius: 5px;
        margin-right: 10px;
    }
}