@import './variable.scss';

.filtri-ricerca {
    border-bottom: 1px solid $border-color;

    .collapse-header {
        &:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            }
        button:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
            outline: none;
        }
        [data-toggle=collapse] {
            background-color: $bg-color-box !important;
            border-color: $border-color !important;
            color: $color-principale !important;
        }

        [data-toggle=collapse]:before {
            float: left;
            width: 1.5rem;
            line-height: .1rem;
            margin-right: 10px;
            content: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cg %3E%3Cpath fill='%230066cc' d='M 6.17 15.06 C 8.115 15.077 16.192 15.045 17.509 15.046 C 17.925 15.046 18.174 14.551 17.876 14.261 L 12.463 8.777 C 11.875 8.198 11.757 8.29 11.169 8.869 L 5.895 14.367 C 5.597 14.657 5.754 15.056 6.17 15.06 Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
        }
    
        [data-toggle=collapse][aria-expanded=false]:before {
            -webkit-transform: scale(-1);
            transform: scale(-1);
        }

    }

    .collapse-body {
        padding: 12px 24px 20px;
    }
}


small.invalid-feedback {
    display: block;
}

small.invalid-feedback.form-text.text-muted {
    color: var(--red) !important;
    padding: 2px 3px;
    font-weight: 600;
    margin: 0;
}

.form-custom {

    &.form-bg {
        background-color: $bg-color-box;
    }

    .form-group {
        margin-bottom: 2rem;
        label {
           text-overflow: unset;
        }
    }

    input[type=text].form-control,
    input[type=number].form-control,
    input[type=date].form-control,
    input[type=email].form-control,
    input[type=tel].form-control,
    input[type=password].form-control,
    textarea  {
        @include style-form-input;

        &.is-invalid {
            background-color: white;
        }
        &:not([readonly]):focus {
            @include border-input-focus;
        }
        &[disabled] {
            @include campo-disabilitato();
        }
    }

    .input-group-text  {
        @include style-form-input-lati(2px, 2px, 2px, 0);
    }
    .css-dw8hx6-container,
    .css-sjawnh-control, 
    .css-duooqy-control,
    .css-13cymwt-control,
    .css-16xfy0z-control {
        @include style-form-input;
    }
    .css-1p3m7a8-multiValue {
        background-color: hsl(0, 0%, 54%);
        color:white;
        div {
            color:white;
            font-weight: bold;
        }
    }
    //campo disabilitato
    .css-16xfy0z-control,
    .css-dw8hx6-container {
       @include campo-disabilitato();
    }

    .form-check [type=checkbox]:disabled,
    .form-check [type=radio]:disabled {
        &:not(:checked)+label::after {
            @include check-disabilitato();
        }
        &:checked+label::after {
            @include check-disabilitato(true);
        }
    }

    .css-bzfal8-control {
        border:none;
        background-color: initial;
    }
    .react-autocomplete__control {
        @include style-form-input;
    }
    
    div[class^="indicatorContainer"] {
        padding: 6px;
    }
    .react-autocomplete__menu, .css-26l3qy-menu {
        margin-top: 0px;
    }
    .react-autocomplete__value-container {
        padding:0 8px;
    }
    .react-autocomplete__input-container {
        padding: 0;
        margin: 0;
    }
    .react-autocomplete__option {
        font-weight: 500;
        font-size: 16px;
        padding: 5px 12px;
        color: $color-principale;
        //item selezionato
        &--is-selected {
            background-color: $color-principale !important;
            color: white;
            font-weight: 600;
            //text-decoration: underline;
        }
        //item hover
        &--is-focused {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-color: #DEEBFF;
            text-decoration: underline;
            font-weight: 600;
            cursor:pointer;
        }
    }

    button:not(.btn-xs)  {
        &.btn {
            height: 2.5rem;
            padding: 10px 24px;
        }
    }

    //select
    .select-pill {
        font-weight: 500;
        font-size: 16px;
        div {
            padding: 5px 12px;
        }
        //hover sull'item della select
        .css-1cqcnmk-option {
            font-weight: 600;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
            background-color: #DEEBFF;
        }
        //item selezionato
        .css-1ukorqb-option {
            background-color: $color-principale !important;
            color: white;
            font-weight: 600;
            text-decoration: none;
        }
    }

    textarea ~ .invalid-feedback.form-text.text-muted{
        transform: translateY(-10px);
    }
}

.form-inserimento {
    padding: 40px 20px 20px 20px;

    .form-group {
        margin-bottom: 3rem;
    }
    .form-check{
        margin-bottom: 2rem;
        .form-check-label {
            font-weight: 600;
            padding-left: 36px;
        }
    }

}

.form-modal {
    padding: 10px;
    margin: 10px;
    @include border();

    .form-group {
        margin-bottom: 3rem;
    }
}

.form-check-inline .form-group > label {

    padding-left: 30px;
    margin: 0;

    &.active {
        -webkit-transform: unset;
         transform: unset
    }
}