@import '../../style/variable.scss';
@import '../../style/mixin.scss';

.gestione-cv {

    .content-page-cv {
        background-color: $bg-color-box;
        @include border();
        padding:20px;
    }
    

    .private-tab {
        .privacy-content{
            overflow: auto;
            height: 200px;
        }
    }

    .checkbox-tipo-dipendente label.active {
        box-shadow: none !important;
        -webkit-transform: none;
    }
    
    /*   .checkbox-tipo-dipendente {
        margin-bottom: 0.5rem;
    } */

    .checkbox-tipo-dipendente label {
        margin-bottom: 0;
    }
    .form-custom .form-group {
        margin-bottom: 3rem;
    }

    .table-striped tbody tr:nth-of-type(odd) {
        background-color: white;
    }
    .table-striped tbody tr:nth-of-type(even) {
        background-color: rgb(235, 235, 235);
    }

    .box-nuova-attivita,
    .box-nuova-lingua {
        background-color: white;
        margin-top: 25px;
        padding: 20px;
        @include border();
    }

    .icon {
        width: 24px;
        height: 24px;
    }
}


.react-tabs__tab--disabled {
    cursor:not-allowed;
}

.react-tabs__tab--disabled:focus {
    outline: none;
    box-shadow: 0 0 0 0px #b1b1b3;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    padding-bottom: 0;
    cursor:not-allowed;
}

.react-tabs__tab--selected {
    outline: none;
    box-shadow: 0 0 0 0px #b1b1b3;
    background: #003a56;
    color: white;
    font-weight: 600;
}




