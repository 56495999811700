//MIXIN
@mixin style-form-input($spessore: 2px, $borderRadius: 5px) {
    border: $spessore solid #bbbbbb !important;
    border-radius: 5px !important;
    cursor: pointer;
}

@mixin style-form-input-lati($top, $right, $bottom, $left, $borderRadius: 5px) {
    border-style: solid !important;
    border-color: #bbbbbb !important;
    border-top: $top;
    border-right: $right;
    border-bottom: $bottom;
    border-left: $left;
    border-radius: $borderRadius !important;
    @if $top == 0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
      }
    @if $right == 0 {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    @if $bottom == 0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
    @if $left == 0 {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
      }
}

@mixin border() {
  border-radius: 10px;
  border: 1px solid $border-color !important;
} 

@mixin border-input-focus() {
  border-color: $border-color-focus !important;
  -webkit-box-shadow: 0 0 0 2px $border-color-focus !important;
  box-shadow: 0 0 0 2px $border-color-focus !important;
  outline: 0;
} 

@mixin label-required() {
  content: '*';
  color: $color-red;
  font-size: 22px;
  line-height: 16px;
  top: 3px;
  position: relative;
  left: 5px;
  font-weight: 600;
}


@mixin campo-disabilitato() {
  cursor: not-allowed;
  background-color: $bg-campo-disabilitato;
  opacity: 1;
}

@mixin check-disabilitato($checked: false) {
  cursor: not-allowed;
  border-color: #919191;
  background-color: $bg-campo-disabilitato;
  @if $checked == true {
    background-color: $bg-check-disabilitato;
  }
  opacity: 1;
}