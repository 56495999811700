@import '../../../style/variable.scss';

.leggi-tutto {
    font-style: italic;
    color: $color-principale;
    font-weight: 600;
    margin-left: 5px;
    text-wrap: nowrap;
    
    &:hover {
        color: $color-secondario;
    }
    &:focus {
        -webkit-box-shadow: 0;
        box-shadow: none;
    }
}