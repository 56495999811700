
.page-header h2 {
    text-transform: capitalize;
    margin: 0;
}

.page-top-header {
    .titolo {
        font-size: 48px;
        font-weight: 700;
        line-height: 1em;
    }
    .descrizione-header{
        font-size: 24px;
        font-weight: 500;
    }
    .sub-descrizione-header{
        font-size: 21px;
        font-style: italic;
        font-weight: 500;
    }
}